import React, { useState} from "react";
import Layout from "../components/layout";
import {  graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SvgIcons from "../components/svgIcons/svgIcons";

const ProductItemTemplate = ({ data }) => {
  const {
    name,
    name_alt,
    description,
    parameters,
    image,
    documentation,
/*     seo_title,
    seo_description,
    seo_keywords,
    strapi_id, */
  } = data.strapiProduct;
  const img = getImage(image[0].localFile.childImageSharp);

  let сontent = description.data.description;
  const [productContent, setProductContent] = useState(сontent);

  const [tooglePdf, setTooglePdf] = useState(false);
  const [toogleContent, setToogleContent] = useState(true);

  function desc(content) {
    if (content === "content") {
      setToogleContent(true);
      setTooglePdf(false);
    } else {
      setToogleContent(false);
      setTooglePdf(true);
    }
  }

  return (
    <Layout>
      <div className="container mt-60">
        <div className="product-item">
          <div className="product-item__img">
            <GatsbyImage image={img} alt={name} />
          </div>
          <div className="product-item__body">
            <div className="product-item__header">
              <h1>{name}</h1>
              <span>{name_alt}</span>
            </div>
            <div className="product-item__desc mt-30 ">
              <div className="product-item__btn mb-30">
                <Link to="/form" className="btn-order info_link">
                  Заказать
                </Link>
              </div>
              <div
                className="product-parameters"
                dangerouslySetInnerHTML={{
                  __html: parameters.data.parameters,
                }}
              />
            </div>
          </div>
        </div>

        <div className="impact__block mt-90 mb-60">
          <div className="impact__line"></div>
          <div className="impact__text"></div>
          <div className="impact__line"></div>
        </div>

        <div className="mt-60 product__tab">
          <div className="product__tabHeader">
            <h2
              className={tooglePdf ? "pointer no-active" : "pointer active"}
              onClick={() => desc("content")}
            >
              Описание
            </h2>
            <h2
              className={tooglePdf ? "pointer active" : "pointer no-active"}
              onClick={() => desc("docum")}
            >
              Документация
            </h2>
          </div>
          <div className="product__content">
            <div
              className={
                toogleContent
                  ? "product-description"
                  : "product-description display-none"
              }
              dangerouslySetInnerHTML={{
                __html: productContent,
              }}
            />
            <div
              className={
                tooglePdf ? "product__pdfList" : "product__pdfList display-none"
              }
            >
              {documentation.map((pdf) => {
                return (
                  <div
                    key={pdf.pdf.localFile.size}
                    className="product__pdfListItem"
                  >
                    <div className="product__pdfIcon">
                      <SvgIcons width="42" height="42" name="pdf2" />
                    </div>
                    <div className="product__pdfText">
                      <a target="_blanck" href={pdf.pdf.localFile.publicURL}>
                        {pdf.type_document}
                      </a>
                    </div>
                  </div>
                );

                //console.log("documentation---------->", pdf.pdf.localFile.url);
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductItemTemplate;

export const productQuery = graphql`
  query Product($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      name
      name_alt
      seo_title
      seo_keywords
      seo_description
      strapi_id
      documentation {
        pdf {
          localFile {
            absolutePath
            size
            url
            publicURL
          }
        }
        type_document
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 350)
          }
        }
      }
      description {
        data {
          description
        }
      }
      parameters {
        data {
          parameters
        }
      }
    }
  }
`;
